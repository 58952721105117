export default {
  methods: {
    stateFlashcard (label) {
      if (label === 'Fácil') {
        return 'FACIL'
      } else if (label === 'Normal') {
        return 'NORMAL'
      } else if (label === 'Difícil') {
        return 'DIFICIL'
      } else if (label === 'No lo sé') {
        return 'NO_LA_SE'
      }
    },
    descriptionFlashcard (label) {
      if (label === 'FACIL') {
        return 'Fácil'
      } else if (label === 'NORMAL') {
        return 'Normal'
      } else if (label === 'DIFICIL') {
        return 'Difícil'
      } else if (label === 'NO_LA_SE') {
        return 'No lo sé'
      }
    }
  }
}
