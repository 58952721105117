<template>
  <v-progress-linear
    :value="progress"
    class="bar"
    height="65"
    width="100%"
    background-color="#fff"
    :color="barColor"
  >
    <!-- <strong>{{ Math.ceil(progress) }}%</strong> -->
    <span class="bar__label d-flex justify-center align-center">{{ level }}</span>
  </v-progress-linear>
</template>

<script>
import '@/styles.scss'

export default {
  inheritAttrs: false,
  name: 'FlashcardLevelBar',
  data () {
    return {
      barColor: '$progress-linear-stripe-gradient'
    }
  },
  props: {
    progress: {
      type: [String, Number],
      default: '100'
    },
    level: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>

.bar {
  // background: linear-gradient(90deg, #1FC1D0 0%, #0583DA 100%) !important;
  height: 65px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15) !important;
  border-radius: 9px;
  // gradient color
  background-image:
  linear-gradient(90deg, #1FC1D0 0%, #0583DA 100%),
  linear-gradient(90deg,  #1FC1D0 100%, #0583DA 100%);
  background-size:var(--p,50%) 100%,100% 100%;
  background-position:right,left;
  background-repeat:no-repeat;
  &__label {
    background: #fff;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    border-radius: 10px;
    height: 32px;
    padding: 0 10px;
  }
}
@media (max-width: 960px) {
  .bar {
    height: 35px !important;
    &__label {
      font-size: 10px;
      line-height: 15px;
      height: 17px;
    }
  }
}

@media only screen and (min-width: 769px) and (max-width: 1281px) {
  .bar {
    margin-bottom: 8px;
  }
}
</style>
