import { render, staticRenderFns } from "./FlashcardLevelBar.vue?vue&type=template&id=966a498c&scoped=true"
import script from "./FlashcardLevelBar.vue?vue&type=script&lang=js"
export * from "./FlashcardLevelBar.vue?vue&type=script&lang=js"
import style0 from "./FlashcardLevelBar.vue?vue&type=style&index=0&id=966a498c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "966a498c",
  null
  
)

export default component.exports