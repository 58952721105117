<template>
  <div id="app" class="d-flex flex-column align-center justify-center">
    <viewer
      :options="options"
      @inited="inited"
      class="viewer"
      ref="viewer"
    >
      <img
        :src="imageSrc"
        class="image"
        @click.stop="showImage"
      >
    </viewer>
  </div>
</template>

<script>
import 'viewerjs/dist/viewer.css'
import { component as Viewer } from 'v-viewer'

export default {
  components: {
    Viewer
  },
  data () {
    return {
      options: {
        toolbar: false,
        navbar: false
      }
    }
  },
  props: {
    imageSrc: {
      type: String,
      default: ''
    }
  },
  methods: {
    inited (viewer) {
      this.$viewer = viewer
    },
    showImage () {
      this.$viewer.show()
    }
  }
}
</script>

<style>
.image {
  height: 223px !important;
  max-width: 223px;
}
@media (max-width: 960px) {
  .image {
    max-height: 162px !important;
    width: 162px !important;
  }
}
</style>
